/* You can add global styles to this file, and also import other style files */

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: rgb(7, 7, 7);
}
